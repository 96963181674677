.dealer-categories {
  .category-posts {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .dealer-col {
      padding: 5px;

      @include flex(0, 0, 50%);

      @include tablet {
        @include flex(0, 0, 25%);
      }

      @include laptop {
        padding: 15px;

        @include flex(0, 0, 20%);
      }
    }

    .dealer-image {
      background-size: cover;
      background-position: center;
      display: block;

      @include aspect-ratio(1, 1);

      &::after {
        content: '';
        background-color: $brand-primary;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }

      span.title {
        position: absolute;
        font-size: 20px;
        font-weight: 600;
        color: gray;
        text-align: center;
        text-transform: uppercase;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -55%);
      }

      span.login {
        color: $white;
        font-size: 18px;
        font-weight: 700;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
      }

      &::after,
      span.login {
        position: absolute;
        opacity: 0;
        visibility: hidden;

        @include links-transition();
      }

      &:hover {
        &::after,
        span.login {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}

.single-dealers iframe {
  width: 100%;
  margin-bottom: 1.5rem;
}

.doc-col {
  @include tablet {
    column-count: 2;
  }

  > div {
    break-inside: avoid;
  }
}
