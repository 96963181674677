.hero-panel,
.prefooter-panel {
  position: relative;
  background-color: #e7e7e7;

  .container {
    position: relative;
    z-index: 1;
  }
}

body.home .hero-container {
  @include laptop {
    max-height: 800px;
  }

  @include wide {
    max-height: 1200px;
  }

  .hero-panel {
    @include laptop {
      min-height: 350px;
    }

    @include wide {
      min-height: 530px;
    }
  }

  .hero-content {
    @include wide {
      padding: 149px 0 160px;
    }

    @include hd {
      padding: 149px 0 224px;
    }
  }
}

.hero-object {
  position: relative;
  transform: translateY(-55%);
  top: 55%;
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 430px;

  @include laptop {
    position: absolute;
    right: 0;
  }

  @include desktop {
    max-width: 575px;
  }

  @include wide {
    max-width: 650px;
  }

  @media (min-width: 1800px) {
    max-width: 773px;
    right: -107px;
  }
}

.hero,
.prefooter {
  background-size: cover;
  height: 250px;

  @include tablet {
    height: 400px;
  }

  @include laptop {
    position: absolute;
    height: 100%;
    width: 100%;
  }
}

.hero-content {
  padding: 30px 0;
  text-align: center;

  @include tablet {
    padding: 40px 0;
  }

  @include laptop {
    width: 500px;
    padding: 120px 0 100px;
    text-align: left;
  }

  @include desktop {
    width: 600px;
  }

  @include wide {
    width: 700px;
  }

  @include hd {
    width: 900px;
  }

  .line1 {
    font-size: 14px;
    font-weight: 600;
    font-family: $primary-font;
    text-transform: uppercase;

    @include laptop {
      margin-bottom: 0;
    }

    @include desktop {
      font-size: 16px;
    }

    @include wide {
      font-size: 18px;
    }
  }

  .line2 {
    font-size: 45px;
    margin-top: -4px;
    margin-left: -5px;
    font-style: italic;
    line-height: .83;

    @include laptop {
      font-size: 70px;
    }

    @include desktop {
      font-size: 80px;
    }

    @include wide {
      font-size: 95px;
    }

    @include hd {
      font-size: 120px;
    }
  }

  .cta-container {
    margin-top: 13px;

    .button {
      margin-top: 10px;
      position: relative;
      z-index: 0;

      &::before {
        @include tablet {
          content: '';
          position: absolute;
          z-index: -1;
          top: 0;
          bottom: 0;
          width: 50px;
          transform: skewX(-45deg);

          @include links-transition();
        }
      }
    }

    .button:nth-of-type(odd) {
      @extend .primary;

      @include tablet {
        padding-right: 14px;
      }

      &::before {
        background-color: $brand-primary;
        right: -26px;
      }

      &:not(:last-of-type) {
        @include tablet {
          margin-right: 70px;
        }
      }

      &:hover::before {
        background-color: lighten($brand-primary, 10%);
      }
    }

    .button:nth-of-type(even) {
      @extend .secondary;

      @include tablet {
        padding-left: 4px;
      }

      &::before {
        background-color: $brand-secondary;
        left: -26px;
      }

      &:not(:last-of-type) {
        @include tablet {
          margin-right: 16px;
        }
      }

      &:hover::before {
        background-color: lighten($brand-secondary, 14%);
      }
    }
  }
}

.overlay::after {
  @include laptop {
    content: '';
    background: linear-gradient(0.25turn, rgba($white, 0.85) 46%, rgba($white, 0) 63%);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.strapline {
  text-align: center;
  padding: 25px 0 20px;
  position: relative;
  z-index: 1;

  @include laptop {
    padding: 30px 0 25px;
  }

  @include wide {
    padding: 44px 0 38px;
  }

  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 2.2;
    letter-spacing: -0.1px;
    color: $white;
    max-width: 1210px;
    margin-left: auto;
    margin-right: auto;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.prefooter {
  background-color: rgba($black, 0.4);
  background-repeat: no-repeat;
  background-blend-mode: overlay;
}

.prefooter-panel .hero-content {
  @include wide {
    padding: 140px 0;
  }

  @include hd {
    padding: 190px 0 180px;
  }

  .line1,
  .line2 {
    @include laptop {
      color: $white;
    }
  }
}
