#skip-navigation {
  position: absolute;
  display: block;
  background: $white;
  color: $brand-primary;
  padding: 15px;
  text-align: center;
  transform: translateY(-101%);
  transition: transform 0.25s ease-in-out;
  z-index: 10;

  &:focus {
    transform: translateY(0);
    transition: transform 0.25s ease-in-out;
  }
}

#modal {
  overflow: scroll;
  background-color: rgba($black, 0.5);

  .modal-title {
    color: $brand-primary;
    font-family: $secondary-font;
  }

  .modal-dialog {
    @include tablet {
      max-width: 75%;
    }

    @include hd {
      max-width: 50%;
    }
  }
}

.brand {
  max-width: 230px;

  @include tablet {
    max-width: 360px;
  }

  @include wide {
    max-width: 430px;
  }

  @include hd {
    max-width: 490px;
  }
}

.banner {
  background-color: $brand-secondary;
  border-bottom: 4px solid $brand-primary;
  position: relative;
  z-index: 2;

  a,
  [href^="tel:"] {
    color: $white;
    font-style: italic;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.2px;
    font-size: 14px;

    @include wide {
      font-size: 15px;
    }
  }

  .brand {
    @include tablet {
      position: absolute;
      z-index: 3;
      padding: 10px 30px 12px 0;
    }

    @include desktop {
      padding: 16px 30px 22px 0;
    }

    @include wide {
      padding: 20px 55px 18px 0;
    }

    &::before {
      @include tablet {
        content: '';
        background-color: $brand-secondary;
        box-shadow: 3px 0 0 4px $brand-primary;
        position: absolute;
        z-index: -1;
        top: 0;
        right: 0;
        bottom: 0;
        left: -1000px;
        transform: skewX(-45deg);
      }
    }
  }

  .nav {
    justify-content: flex-end;
    margin-right: -5px;

    >li > a {
      padding-top: 17px;
      padding-bottom: 9px;
      padding-right: 5px;
      padding-left: 5px;

      @include wide {
        padding-top: 20px;
        padding-bottom: 12px;
      }

      @include hd {
        padding-top: 22px;
        padding-bottom: 14px;
      }
    }

    > li:not(:first-of-type) {
      margin-left: 15px;

      @include wide {
        margin-left: 44px;
      }

      @include hd {
        margin-left: 64px;
      }
    }
  }

  .nav-top {
    position: relative;
    z-index: 2;

    &::before {
      content: '';
      background-color: $brand-primary;
      position: absolute;
      z-index: -1;
      top: 0;
      right: -10000px;
      bottom: 0;
      left: -10000px;
    }

    a:not([href^="tel:"]):hover {
      color: $black;
    }
  }

  .nav-primary {
    a:hover {
      color: #f00;
    }
  }
}

.banner li.menu-item-has-children.open {
  box-shadow: 0 8px 0 $brand-primary;

  > a {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      z-index: 2;
      bottom: -15px;
      left: 50%;
      transform: translateX(-50%);
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 7px solid $brand-primary;
    }
  }

  .sub-menu {
    visibility: visible;
    opacity: 1;
  }
}

.banner .nav-top li.menu-item-has-children.open {
  box-shadow: 0 8px 0 $brand-secondary;

  > a::before {
    border-top: 7px solid $brand-secondary;
  }
}

.banner .sub-menu {
  visibility: hidden;
  opacity: 0;
  background-color: $white;
  border: 2px solid #838383;
  border-radius: 16px;
  padding: 10px 0;
  margin-top: 8px;
  margin-left: -30px;
  z-index: 1;
  position: absolute;

  @include links-transition((visibility, opacity, top));

  a {
    color: $black;
    font-weight: 500;
    padding: 5px 30px;

    &:hover {
      color: $brand-primary;
    }
  }
}

li.image-sub-menu .sub-menu {
  max-width: 760px;

  @include desktop {
    padding: 25px 14px 0;
    // column-count: 2;
    // column-gap: 6px;
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  @include wide {
    // column-count: 3;
  }

  li {
    // break-inside: avoid;
    display: flex;
    align-items: flex-end;

    @extend .col-xl-4;

    @include desktop {
      padding-bottom: 20px;
    }
  }

  a {
    @include desktop {
      text-align: center;
      padding: 0 0;
    }

    img {
      display: none;
      max-width: 224px;
      max-height: 65px;
      padding: 0 4px;

      @include desktop {
        display: block;
      }
    }

    > span {
      display: block;

      @include desktop {
        margin-top: 1rem;
      }
    }
  }
}

.banner,
.sideNav {
  li.menu-item-has-children > a {
    @extend .down-caret;

    &::after {
      margin-left: 7px;
      position: relative;
    }
  }

  li.menu-item-has-children.open > a {
    @extend .up-caret;
  }

  .sub-menu {
    list-style: none;
  }
}
