.slider-nav {
  position: relative;
  display: inline-block;
  padding: 0 23px;

  .slick-dots {
    position: static;
    width: auto;
    display: flex !important;
    align-items: center;

    li,
    button,
    button::before {
      width: auto;
      height: auto;
    }

    li {
      margin: 0 5px;
    }

    button::before {
      content: '\f2f8';
      font-family: 'Font Awesome 6 Pro', sans-serif;
      font-size: 25px;
      font-weight: 700;
      position: relative;
      opacity: 1;
      color: $black;
    }

    .slick-active button::before {
      color: $brand-primary;
      opacity: 1;
    }
  }

  .slick-arrow {
    height: auto;
    width: auto;

    &::before {
      font-family: 'Font Awesome 6 Pro', sans-serif;
      font-weight: 700;
      font-size: 26px;
      color: $black;
      opacity: 1;
    }

    &.slick-prev {
      left: -15px;

      &::before {
        content: '\f0a8';
      }
    }

    &.slick-next {
      right: -15px;

      &::before {
        content: '\f0a9';
      }
    }
  }
}

body:not(.home) .testimonials-panel {
  padding-top: 0;
}

.testimonials-panel {
  .testimonials-carousel {
    margin: 30px 0 10px;

    @include laptop {
      margin-top: 70px;
      margin-bottom: 52px !important;
    }
  }

  .testimonial {
    @include tablet {
      width: 95% !important;
    }
  }
}

footer.footer {
  padding: 30px 0 18px;

  .sub-menu {
    display: none;
  }

  *,
  a[href^="tel:"] {
    color: $white;
    font-weight: 500;
    font-size: 16px;
  }

  a:not([href^="tel:"]),
  i {
    @include links-transition();

    &:hover {
      color: #f00;
    }
  }

  li:hover i {
    color: #f00;
  }

  .brand {
    margin-bottom: 35px;
  }

  .contact-info {
    margin-bottom: 18px;

    span {
      display: block;

      @include tablet {
        display: inline;
      }

      &:not(:last-of-type)::after {
        @include tablet {
          content: '|';
          margin: 0 10px;
        }
      }
    }
  }

  .nav li {
    margin: 0 20px 15px;
  }
}

footer.copyright {
  background-color: #0d0d0d;
  padding: 10px 0 6px;

  *,
  a:hover {
    color: $white;
    font-weight: 500;
    font-size: 12px;
  }

  a::after {
    display: none;
  }
}
