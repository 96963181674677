.project-gallery {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .gallery-col {
    padding: 5px;

    @include flex(0, 0, 33.333%);

    @include tablet {
      @include flex(0, 0, 25%);
    }

    @include laptop {
      padding: 15px;
    }
  }

  .gallery-image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: block;
    box-shadow: 0 1px 4px 0 rgba($black, 0.3);

    @include aspect-ratio(1, 1);

    img {
      display: none;
    }

    &::after,
    i {
      will-change: visibility, opacity;
      visibility: hidden;
      opacity: 0;

      @include links-transition(all, 0.3s, ease-in-out);
    }

    &::after {
      content: '';
      background-color: $black;

      @include stretch;
    }

    i {
      position: absolute;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      z-index: 300;
      color: $white;
      font-size: 1.5em;
    }

    &:hover {
      &::after {
        visibility: visible;
        opacity: 0.4;
      }

      i {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

.single-trailer-gallery {
  .gallery-col:nth-of-type(n+13) {
    display: none;
  }

  .gallery-col.dark {
    .gallery-image {
      &:hover::after {
        opacity: 0.8 !important;
      }

      &::after {
        visibility: visible !important;
        opacity: 0.7 !important;
      }

      i {
        display: none;
      }

      .gallery-button {
        color: $white;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 100;
        font-weight: 600;
        transform: translate(-50%, -50%);
        width: fit-content;

        @include tablet {
          font-size: 18px;
        }
      }
    }
  }
}

//photo gallery page

.photo-gallery-container {
  margin: 0 -5px;

  @include laptop {
    margin: -15px;
  }
}

.gallery-category {
  margin-bottom: 90px;
  
  @include flex(0, 0, 50%);

  @include tablet {
    @include flex(0, 0, 33.333%);
  }

  @include laptop {
    margin-bottom: 75px;
  }

  .gallery-col {
    position: relative;

    @include flex(0, 0,100%);

    .category-name {
      position: absolute;
      bottom: -60px;
      height: 50px;

      small {
        color: #676767;
      }
    }
  }
}

//images-template
.images-cat {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .image-container {
    padding: 5px;

    @include flex(0, 0, 50%);

    @include tablet {
      @include flex(0, 0, 25%);
    }

    @include laptop {
      padding: 15px;

      @include flex(0, 0, 20%);
    }
  }

  .image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: block;
    border: 1px solid $black;

    @include aspect-ratio(1, 1);
  }
}
