.icon {
  flex-shrink: 0;
  position: relative;
  background-color: $brand-primary;
  border: 5px solid #eaeaea;
  border-radius: 50%;
  height: 110px;
  width: 110px;
  margin: 6px auto 30px;

  @include laptop {
    margin: 6px 20px 0 0;
  }

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

#why-choose {
  .h1 {
    margin-bottom: 10px;

    @include laptop {
      margin-bottom: 31px;
    }
  }

  .feature-col:nth-of-type(3n+1) .feature {
    @include wide {
      margin-right: 0;
      margin-left: 0;
    }
  }

  .feature-col:nth-of-type(3n+2) .feature {
    @include wide {
      margin-right: auto;
      margin-left: auto;
    }
  }

  .feature-col:nth-of-type(3n+3) .feature {
    @include wide {
      margin-right: 0;
      margin-left: auto;
    }
  }

  .feature-col {
    margin-top: 37px;

    .feature {
      margin: 0 auto;

      @include laptop {
        width: 95%;
      }
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  .button {
    margin-top: 39px;
  }
}

#service-panels {
  .service-row {
    position: relative;
    display: flex;
    flex-direction: column;

    .image-container {
      position: relative;
      height: 250px;

      @include tablet {
        height: 400px;
      }

      @include laptop {
        flex-shrink: 0;
        transform: skewX(-12deg);
        overflow: hidden;
        position: absolute;
        height: 100%;
        width: 50%;
      }

      @include desktop {
        width: 59%;
      }

      .image {
        background-size: cover;
        position: absolute;
        height: 100%;
        width: 100%;

        @include laptop {
          transform: skewX(12deg);
        }

        @media (min-width: 1900px) {
          width: 96%;
        }
      }
    }

    .content-container {
      background-blend-mode: color-burn;
      background-size: cover;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;

      @include laptop {
        width: 64%;
        min-height: 480px;
      }

      @include desktop {
        width: 53%;
      }

      @include wide {
        min-height: 566px;
      }

      .content {
        padding: 0 15px;
        text-align: center;
        margin: 0 auto;

        @include laptop {
          text-align: left;
          max-width: 520px;
        }
      }

      .title-image {
        max-width: 340px;

        @include wide {
          max-width: 460px;
        }
      }

      p {
        color: $white;
        font-weight: 500;
        letter-spacing: -0.5px;
      }

      .button span {
        display: none;

        @include tablet {
          display: inline;
        }
      }
    }
  }

  .service-row:nth-of-type(odd) {
    @include laptop {
      flex-direction: row;
    }

    .image-container {
      @include laptop {
        border-right: 10px solid $white;
        left: -5.5%;
      }

      .image {
        @include laptop {
          background-position: right;
          left: 11%;
        }

        @include desktop {
          left: 9.4%;
        }
      }
    }

    .content-container {
      background-image: url('https://imagedelivery.net/hYVz_KLysEMBk-HGZuEADA/592f33c5-e216-484a-85c3-9440f736d200/1024px');
      

      @include laptop {
        background-position: 80%;
        margin-left: auto;
      }

      @include wide {
        background-position: right;
      }

      .content {
        @include laptop {
          margin-right: 40px;
          margin-left: 20%;
        }

        @include wide {
          margin-right: 0;
          margin-left: 28%;
        }
      }
    }
  }

  .service-row:nth-of-type(even) {
    @include laptop {
      flex-direction: row-reverse;
    }

    .image-container {
      @include laptop {
        border-left: 10px solid $white;
        right: -5.7%;
      }

      .image {
        @include laptop {
          background-position: left;
          right: 9.4%;
        }
      }
    }

    .content-container {
      background-image: url('https://imagedelivery.net/hYVz_KLysEMBk-HGZuEADA/24b9b1c2-7868-4558-6460-c45d75b47b00/1024px');
      background-position: 100%;

      @include laptop {
        background-position: 20%;
        margin-right: auto;
      }

      @include wide {
        background-position: left;
      }

      .content {
        @include laptop {
          margin-right: 20%;
          margin-left: 40px;
        }

        @include wide {
          margin-right: 28%;
          margin-left: auto;
        }
      }
    }
  }

  .service-row:not(:last-of-type) {
    margin-bottom: 9px;
  }
}
