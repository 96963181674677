a[href^="tel:"] {
  color: $body-color;
}

a[href$=".pdf"]::after {
  content: '\f1c1';
  font-family: 'Font Awesome 6 Pro', sans-serif;
  margin-left: 7px;
  font-size: 18px;
  font-weight: 700;
}

.no-icon::after {
  content: '' !important;
}

.google-map {
  position: relative;
  overflow: hidden;
  flex-grow: 1;

  iframe {
    width: 100%;
    height: 100%;
    min-height: 400px;
    border: 0;
    position: relative;
  }
}

.search-form {
  display: flex;
  flex-wrap: wrap;

  label {
    flex-grow: 1;
    margin-bottom: 0;
  }
}

.pagination-style {
  a,
  span {
    margin-right: 10px;
    text-decoration: none !important;
  }

  .nav-links {
    padding: 5px 0 5px 10px;
    border-radius: 0;
  }
}

.page-header {
  text-align: center;
  margin-bottom: 30px;

  @include tablet {
    margin-top: 25px;
  }

  @include laptop {
    margin-bottom: 45px;
  }

  @include wide {
    margin-bottom: 60px;
  }
}

.page-content-panel {
  h2,
  .h2 {
    margin-bottom: 20px;
  }

  > .container > .row > div > h2:not(:first-child) {
    margin-top: 30px;
  }
}

.two-col {
  @include tablet {
    column-count: 2;
    column-gap: 50px;
  }

  > * {
    break-inside: avoid;
  }
}

.post-password-form label {
  display: block;
}

hr {
  margin: 2rem 0;
}

.warranty-wrapper {
  iframe {
    border: none;
    width: 100%;
    height: 5000px;
  }
}
