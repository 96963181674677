@include flex-sticky-footer();

html,
body {
  @include text-style($body-font-sizes, $body-font, $body-weight, $body-color, $body-line-height);
  @include smooth-fonts;

  background-color: $body-bkg;
  overflow-x: hidden;
}

img {
  max-width: 100%;
  height: auto;
}

.image__bkg {
  &-center {
    @include object-fit(center);
  }

  &-top {
    @include object-fit(top);
  }

  &-right {
    @include object-fit(right);
  }

  &-left {
    @include object-fit(left);
  }

  &-bottom {
    @include object-fit(bottom);
  }

  &-top-left {
    @include object-fit(top left);
  }

  &-top-right {
    @include object-fit(top right);
  }

  &-bottom-left {
    @include object-fit(bottom left);
  }

  &-bottom-right {
    @include object-fit(bottom right);
  }
}

h1,
.h1 {
  font-style: italic;

  @include text-style($h1-font-sizes, $heading-font, $h1-weight, $heading-color, 0.83);
}

h2,
.h2 {
  @include text-style($h2-font-sizes, $heading-font, $h2-weight, $heading-color, 1.1);
}

h1,
h2 {
  > span {
    color: $brand-primary;
  }
}

h3,
.h3 {
  @include text-style($h3-font-sizes, $heading-font, $h3-weight, $heading-color, 1.3);
}

h1,
h2,
h3 {
  text-transform: uppercase;
}

p,
li {
  @include text-style($body-font-sizes, $body-font, $body-weight, $body-color, $body-line-height);
}

a {
  @include links-transition(color);

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

ul.nav a {
  display: block;
}

.panel {
  @include section-padding;
}

.wp-block-embed.is-type-video {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 35px;
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.up-caret {
  @include fa-pseudo($icon: '\f0d8', $position: 'after', $weight: 700, $size: inherit, $color: inherit);
}

.down-caret {
  @include fa-pseudo($icon: '\f0d7', $position: 'after', $weight: 700, $size: inherit, $color: inherit);
}

.underline {
  border-bottom: 2px solid #4a4b47;
  padding-bottom: 7px;
  margin-bottom: 16px;
  letter-spacing: 0.5px;
}

.border {
  border-color: $black !important;
}

.bg-primary {
  background-color: $brand-primary;
}
