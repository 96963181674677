.trailer-content {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: rgba($black, 0.5);
  background-blend-mode: overlay;
  border-radius: 16px;
}

.trailer-summary {
  p,
  a {
    color: $white;
    font-weight: 500;
  }
}

.title-logo {
  max-width: 460px;
  width: 100%;
}

.subtitle-logo {
  max-width: 300px;
  width: 100%;
}

.sidebar .subtitle-logo,
.title-logo-link .title-logo {
  @include links-transition();

  &:hover {
    filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.8));
  }
}

.trailer-table-container {
  overflow-x: scroll;
  width: 100%;
}

.trailer-table-container table {
  tr {
    margin-bottom: 35px;
    position: relative;
  }

  th,
  td {
    padding: 10px;
    min-width: 152px;
  }

  tbody tr:nth-of-type(odd) td {
    background-color: $white;
  }

  tbody tr:nth-of-type(even) td {
    background-color: #f2f2f2;
  }

  tbody tr:last-of-type {
    border-bottom: 1px solid #f2f2f2;
  }

  tr > td:first-of-type,
  tr > th:first-of-type {
    position: sticky;
    left: 0;
    filter: drop-shadow(1px 0 0 $black);
  }

  tr > td:not(:first-of-type),
  tr > th:not(:first-of-type) {
    text-align: center;
  }
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px;
  -webkit-overflow-scrolling: auto;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.trailer-featured-image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  @include aspect-ratio(2, 1);
}
